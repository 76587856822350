::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4a4a4a !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4a4a4a !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #4a4a4a !important;
  opacity: 1; /* Firefox */
}

.open-sans {
  font-family: "open sans" !important;
  color: #4a4a4a !important;
}
