/* .img-magnifier-container {
  position:relative;
  cursor:none;
}
.img-magnifier-container:hover .img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  width: 100px;
  height: 100px;
  display:block;
}
.img-magnifier-glass {
  display:none;
} */

/* canvas {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 7px solid rgba(255, 255, 255, .1);
  box-shadow: 0px 0px 10px #00000038;
	cursor: pointer;
	box-sizing: border-box;
}

.info {
	position: absolute;
	bottom: 1rem;
	left: 0;
	font-family: monospace;
	width: 100%;
	text-align: center;
	font-size: 1rem;
} */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4a4a4a;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4a4a4a;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #4a4a4a;
  opacity: 1; /* Firefox */
}
