@media screen and (orientation: portrait) {
  #btn {
    display: "none !important";
  }
}
/* Landscape orientation */
@media screen and (orientation: landscape) {
  #btn {
    display: "none !important";
  }
}
